import { useDoctorReferStore } from "modules/doctorRefer/DoctorReferStore"
import PropTypes from 'prop-types'
import { formatMoney } from "utils/money"

export default function CostBreakdown({invoice}) {
  const [ hasReader, readerCost, sensorCost, ongoingMonthlyCost, totalDueToday ] = useDoctorReferStore( state => [ state.refer.hasReader, state.refer.readerCost, state.refer.sensorCost, state.refer.ongoingMonthlyCost, state.refer.totalDueToday ] )

  const upFrontCost = hasReader ? sensorCost : sensorCost + readerCost

  const dueToday = hasReader ? totalDueToday - readerCost : totalDueToday

  return (
    <div className="flex flex-col">
      <details>
        <summary className="text-lg md:text-xl font-sourceserif4 cursor-pointer" data-testid="cost-breakdown-summary">
          {`Cost Breakdown`}
        </summary>
        <div className="my-4">
          <p className="text-xs font-semibold text-indigo md:text-base">
            {`After insurance, you pay `}
            <span className="font-light text-[16px] mx-[2px]" data-testid="breakdown-upfront-cost">{formatMoney( upFrontCost )}</span>
            {` upfront.`}
          </p>
          <div className="mt-4">
            {
              invoice?.details.filter( ( detail ) => ( hasReader === null || hasReader === false ) || ( hasReader === true && detail.is_sensor === true ) ).map( ( detail, index ) => (
                <div key={index} className="flex justify-between mb-2">
                  <p className="text-xs capitalize" data-testid={`breakdown-rx-item-${index}`}>{detail.item_description.toLowerCase()}</p>
                  <div className="flex flex-col items-end">
                    {
                      !detail.is_sensor ? (
                        <>
                          <p className="text-xs" data-testid="breakdown-setup-reader-cost">{formatMoney( readerCost )}</p>
                          <p className="text-xs" data-testid="breakdown-setup-reader-charge-type">{`(one-time)`}</p>
                        </>
                      ) :
                        (
                          <>
                            <p className="text-xs" data-testid="breakdown-setup-sensor-cost">{formatMoney( sensorCost )}</p>
                            <p className="text-xs" data-testid="breakdown-setup-sensor-charge-type">{ dueToday > 0 ? `(for three months)` : `(for first month)`}</p>
                          </>
                        )
                    }
                  </div>
                </div>
              ) )
            }
          </div>
        </div>
        <div>
          <p className="text-xs font-semibold text-indigo md:text-base">
            {`After insurance, you will pay `}
            <span className="font-light text-[16px] mx-[2px]" data-testid="breakdown-ongoing-monthly-cost">{formatMoney( ongoingMonthlyCost )}</span>
          </p>
          <div className="mt-4">
            {
              invoice?.details.filter( detail => detail.is_sensor === true ).map( ( detail, index ) => (
                <div key={index} className="flex justify-between mb-2">
                  <p className="text-xs capitalize" data-testid={`breakdown-ongoing-rx-item-label-${index}`}>{detail.item_description.toLowerCase()}</p>
                  <div className="flex flex-col items-end">
                    <p className="text-xs" data-testid={`breakdown-ongoing-sensor-cost`}>{formatMoney( ongoingMonthlyCost )}</p>
                    <p className="text-xs" data-testid={`breakdown-ongoing-sensor-charge-type`}>{`(each month)`}</p>
                  </div>
                </div>
              ) )
            }
          </div>
        </div>
      </details>
    </div>
  )
}

CostBreakdown.propTypes = {
  invoice: PropTypes.object.isRequired
}