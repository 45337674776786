import { useState } from 'react'
import { Payments, OrderSummary, ThankYou } from './pages'
import PageNavigator from 'components/pageNavigator'
import { AOB_PAGES } from './constants'
import { useRetrieveAOBData } from './customHooks'
import { AobLoader, AobError, NoEligibleInvoice, Logo } from './components'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import Noibu from 'utils/Noibu'

export const AOBPaymentsPortal = () => {

  const [ aobIsCompleted, setAobIsCompleted ] = useState( false )
  const [ paymentCompleted, setPaymentCompleted ] = useState( false )
  const [ payLater, setPayLater ] = useState( false )
  const [ invoice, setInvoice ] = useState( null )
  const [ error, setError ] = useState( null )
  const [ notInterested, setNotInterested ] = useState( false )

  const { allowPayment, aobContent } = useRetrieveAOBData( setInvoice, setError )

  if ( allowPayment === false ) return <NoEligibleInvoice />

  const {first_name, last_name, invoice_number, prizm_sno } = invoice || {}

  const noibuCustomAttrs = {
    first_name,
    last_name,
    invoice_number,
    prizm_sno
  }

  if ( aobContent !== undefined && !aobContent ) return (
    <>
      <Noibu customAttrs={noibuCustomAttrs} />
      <AobError message={`Oops! Looks like something went wrong, please call us at ${AF_SUPPORT_PHONE_NUMBER} for help.`} />
    </>
  )

  if ( error ) return (
    <>
      <Noibu customAttrs={noibuCustomAttrs} />
      <AobError message={error} />
    </>
  )

  if ( !invoice ) return <AobLoader message={`Please hold tight while we grab your invoice details...`} withLogo />

  return (
    <>
      <Noibu customAttrs={noibuCustomAttrs} />
      <div className="absolute z-50 top-3 left-0 right-0 max-w-md px-4 mx-auto">
        <Logo />
      </div>
      <PageNavigator
        pageNames={AOB_PAGES}
        pageClassName="max-w-full mx-auto bg-gray3"
        childSharedProps={{
          invoice,
          aobIsCompleted,
          payLater
        }}
      >
        <OrderSummary
          aobContent={aobContent}
          setAobIsCompleted={setAobIsCompleted}
          setError={setError}
          setPayLater={setPayLater}
          setInvoice={setInvoice}
          setNotInterested={setNotInterested}
        />
        <Payments setPaymentCompleted={setPaymentCompleted} />
        <ThankYou
          notInterested={notInterested}
          paymentCompleted={paymentCompleted}
        />
      </PageNavigator>
    </>
  )
}

export default AOBPaymentsPortal
