import { ProductImage } from 'components/productImage'
import PropTypes from 'prop-types'
import { formatDate } from 'utils/time'
import { formatMoney } from 'utils/money'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'

export default function PrescriptionDetails({invoice, items, doctorInfo, hidePrescription, showProductDetails }) {
  const { first_name, last_name, phone } = doctorInfo?.doctor || {}
  const { referral_date, rx_signed_date } = invoice || {}
  const rxDate = rx_signed_date || referral_date || new Date()
  const sensorCost = ( invoice?.details?.filter( detail => detail.is_sensor )?.[0]?.patient_owes || 0 )

  if ( !doctorInfo?.doctor || !items?.length || !invoice ) {
    return (
      <div className="w-full h-full bg-white opacity-75 absolute inset-0 z-100 flex justify-center items-center" data-testid="loading-overlay">
        <span>{`Loading...`}</span>
      </div>
    )
  }

  return (
    <div className="mx-8 lg:mx-0 mt-0 mb-8 p-4 rounded border-2 lg:px-8 lg:py-4 lg:border border-[#A2D4E0]">
      {!hidePrescription && (
        <table className="border-separate border-spacing-y-4">
          <tbody>
            <tr>
              <td>
                <p className="font-medium text-xs md:text-base">{`Date prescribed:`}</p>
              </td>
              <td className="pl-4">
                <p className="text-xs md:text-base" data-testid="rx-date">{formatDate( rxDate )}</p>
              </td>
            </tr>
            <tr>
              <td className="align-top">
                <p className="font-medium text-xs md:text-base">{`Prescribed by:`}</p>
              </td>
              <td className="pl-4">
                <p className="text-xs md:text-base" data-testid="doctor-full-name">{`${first_name} ${last_name}`}</p>
                <p className="text-xs md:text-base" data-testid="doctor-phone">{`${phone}`}</p>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="space-y-4">
        {
          items
            ?.filter( ( item ) => !item.is_sensor && useDoctorReferStore( ( state ) => state.refer.hasReader ) !== true || item.is_sensor )
            .map( ( item, index ) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <div className="flex items-center justify-between gap-2">
                  <ProductImage item={item} />
                  <div className="flex-grow">
                    <p className="text-xs lg:text-base font-semibold lg:font-medium capitalize mb-1" data-testid={`rx-item-label-${index}`}>{item.item_description.toLowerCase()}</p>
                    { showProductDetails && (
                      <div className="flex justify-between font-light text-xs lg:text-sm">
                        <span>{item?.is_sensor && `3 Month Supply`}</span>
                        <span>{item?.is_sensor ? `${formatMoney( sensorCost )} ` : formatMoney( item.patient_owes )}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) )
        }
      </div>
    </div>
  )
}

PrescriptionDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  doctorInfo: PropTypes.object,
  hidePrescription: PropTypes.bool,
  showProductDetails: PropTypes.bool
}
