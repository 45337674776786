import AwsResponse from "types/awsResponse"
import { StatePayer } from "./useInsurancePayers.d"
import { InsurancePayer } from "types/insurancePayer"
import { getAuth } from "modules/aobPayments/fetchPromises"

export const fetchInsurancePayersByState = async ( state : string ) : Promise<AwsResponse<StatePayer[]>> => {

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PAYERS_URL}/form/diabetes_qualify_through_insurance/${state.length ? state : `NC`}` )

  return await response.json()
}


/**
 * Sends a POST request to update insurance information.
 *
 * @param {InsurancePayer} data - The insurance payer data to be updated.
 * @returns {Promise<AwsResponse<any>>} - A promise that resolves to the response from the AWS Lambda function.
 */
export const postInsuranceUpdate = async ( data : InsurancePayer ) : Promise<AwsResponse<any>> => {

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/insurance-update-v2`, {
    method: `PUT`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: getAuth()
    },
    body: JSON.stringify( data )
  })

  return await response.json()
}