import { icoXAob } from 'assets/images'
import { CostBreakdown } from 'components/costBreakdown'
import { DueToday } from 'components/dueToday'
import { SelectInput, TextInput } from 'components/inputs/components'
import { usaShippingRegions } from 'components/inputs/constants'
import { Modal } from 'components/modal'
import { OngoingMonthlyCost } from 'components/ongoingMonthlyCost'
import { SetupCost } from 'components/setupCost'
import { useInsurancePayers } from 'hooks/Insurance'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import PropTypes from 'prop-types'
import { useState } from 'react'
export default function YourCosts({
  invoice,
  error,
  hideBreakdown = false
}) {
  const [ openModal, setOpenModal ] = useState( false )
  const [ selectedState, setSelectedState ] = useState( `` )
  const { payers, payersLoading, refetchPayers } =
    useInsurancePayers( selectedState )
  const [
    hasReader,
    provider,
    totalDueToday,
    sensorCost,
    readerCost,
    ongoingMonthlyCost,
    setReaderCost,
    setSensorCost,
    setProvider,
    setMemberId,
    setTotalDueToday,
    setOngoingMonthlyCost,
    setParentPayerPk
  ] = useDoctorReferStore( state => [
    state.refer.hasReader,
    state.refer.provider,
    state.refer.totalDueToday,
    state.refer.sensorCost,
    state.refer.readerCost,
    state.refer.ongoingMonthlyCost,
    state.setReaderCost,
    state.setSensorCost,
    state.setProvider,
    state.setMemberId,
    state.setTotalDueToday,
    state.setOngoingMonthlyCost,
    state.setParentPayerPk
  ] )

  // no_pay patient: patient_owes = 0, total_to_collect = 0, total_patient_owes = 0

  // pay_now patient: patient_owes > 0, total_to_collect > 0, total_patient_owes > 0

  // pay_later patient: pay_later_eligible_flag = true

  const setUpCost = hasReader
    ? sensorCost
    : readerCost + sensorCost




  const handleSaveSecondaryInsurance = event => {
    event.preventDefault()
    const formData = new FormData( event.target )

    const provider = formData.get( `provider` )

    // get the parent_payer_pk from the selected provider by using the list of payers
    const parent_payer_pk = payers.find( payer => payer.friendly_name === provider )?.parent_payer_pk

    const member_id = formData.get( `member_id` )
    setOpenModal( false )
    setProvider( provider )
    setParentPayerPk( parent_payer_pk )
    setMemberId( member_id )

    setTotalDueToday( 0 )
    setReaderCost( 0 )
    setSensorCost( 0 )
    setOngoingMonthlyCost( 0 )
  }

  function handleChangeState( event ) {
    setSelectedState( event.target.value )
    refetchPayers( event.target.value )
  }

  function handleRemoveSecondaryInsurance() {
    setProvider( `` )
    setMemberId( `` )
    setParentPayerPk( 0 )
    const readerCost = invoice?.details?.filter( detail => !detail.is_sensor )?.[0]?.patient_owes
    const sensorCost = invoice?.details?.filter( detail => detail.is_sensor )?.[0]?.patient_owes
    setReaderCost( readerCost )
    setSensorCost( sensorCost )
    setOngoingMonthlyCost( invoice?.est_monthly_due )
  }

  if ( error ) return <div>{error}</div>

  return (
    <div className="mb-8 font-light">
      <div className="bg-skyBlue p-8 rounded">
        {!hideBreakdown && (
          <>
            <h1 className="text-xl md:text-2xl font-sourceserif4 mb-1">
              {`Your Costs`}
              {provider && ` (Secondary Insurance)`}
            </h1>
            <p className="text-xs md:text-base mb-1">
              {`The costs displayed are based on your primary insurance coverage. The final cost depends on your insurance provider, plan, and deductible. Once your benefits are confirmed, you will receive a final bill if applicable.`}
            </p>
            {!provider && (
              <>
                <p className="text-xs md:text-base mb-1">
                  {`Your cost may be lower with secondary insurance. `}
                  <a
                    onClick={() => setOpenModal( true )}
                    className="text-xs cursor-pointer md:text-base underline text-indigo font-normal"
                    data-testid="add-secondary-insurance-link"
                  >
                    {`Add My Secondary Insurance`}
                  </a>
                </p>
              </>
            )}
            {provider && (
              <div className="text-xs mt-4 flex justify-between">
                <span className="font-semibold">
                  {`Secondary Insurance: ${provider}`}
                </span>
                <a
                  onClick={handleRemoveSecondaryInsurance}
                  className="cursor-pointer underline"
                >
                  {`Remove`}
                </a>
              </div>
            )}
            <Modal
              isOpen={openModal}
              defaultCloseButton={false}
              excludeButtonText="Add My Secondary Insurance"
              onClose={() => setOpenModal( false )}
            >
              <img
                src={icoXAob}
                alt="ico-x"
                className="absolute right-1 top-3 px-3 hover:opacity-80 cursor-pointer object-contain"
                width={40}
                onClick={() => {
                  return setOpenModal( false )
                }}
              />
              <div className="mb-4 w-56 m-auto">
                <h2 className="font-sourceserif4 text-2xl text-center mb-2">
                  {`Add Secondary Insurance Details`}
                </h2>
                <p className="text-center text-xs">
                  {`Our cost estimate is based on the primary and secondary insurance you've provided, but final pricing depends on successful insurance validation. You may be liable for additional costs if the provided insurance information is invalid or expired.`}
                </p>
              </div>
              <form
                onSubmit={handleSaveSecondaryInsurance}
                className="flex flex-col"
              >
                <div className="space-y-2 mb-4">
                  <SelectInput
                    name="state"
                    label="State"
                    className="input-alt"
                    labelClassName="text-xs mb-2"
                    onChange={handleChangeState}
                    required
                    data-testid="secondary-insurance-state"
                  >
                    <option value="">{`Select a State`}</option>
                    {usaShippingRegions.map( region => {
                      return (
                        <option key={region.id} value={region.code}>
                          {region.name}
                        </option>
                      )
                    })}
                  </SelectInput>
                  <SelectInput
                    label="Insurance Provider"
                    name="provider"
                    className="input-alt text-xs"
                    disabled={!selectedState || payersLoading}
                    labelClassName="text-xs mb-2"
                    required
                    data-testid="secondary-insurance-provider"
                  >
                    <option value="">{`Select a Provider`}</option>
                    {payers.map( payer => {
                      return (
                        <option
                          key={payer.parent_payer_pk}
                          value={payer.friendly_name}
                        >
                          {payer.friendly_name}
                        </option>
                      )
                    })}
                  </SelectInput>
                  <TextInput
                    name="member_id"
                    label="Member ID"
                    className="input-alt"
                    labelClassName="text-xs mb-2"
                    required
                    data-testid="secondary-insurance-member-id"
                    onChange={() => null}
                  />
                </div>
                <button
                  type="submit"
                  className="btn-alt self-center bg-purple1"
                  data-testid="save-secondary-insurance-button"
                >
                  {`Save`}
                </button>
              </form>
            </Modal>
            <hr className="border-skyBlue2 my-4" />
          </>
        )}
        <div className="flex justify-between">
          <SetupCost total={setUpCost} />
          <OngoingMonthlyCost total={ongoingMonthlyCost} />
        </div>
        <hr className="border-skyBlue2 my-4" />

        <DueToday
          total={hasReader ? totalDueToday - readerCost : totalDueToday}
          labelClassName={`text-xl md:text-2xl`}
          contentClassName={`text-xl md:text-2xl`}
        />

        {!hideBreakdown && (
          <>
            <hr className="border-skyBlue2 my-4" />
            <CostBreakdown invoice={invoice} />
          </>
        )}
      </div>
    </div>
  )
}

YourCosts.propTypes = {
  invoice: PropTypes.object.isRequired,
  error: PropTypes.string,
  hideBreakdown: PropTypes.bool,
  acceptToc: PropTypes.bool,
  nextPage: PropTypes.func
}
