import { DeferSupplies } from "components/deferSupplies"
import { Faq } from "components/faq"
import { PatientInformation } from "components/patientInformation"
import { PrescriptionConfirmation } from "components/prescriptionConfirmation"
import { PrescriptionDetails } from "components/prescriptionDetails"
import { YourCosts } from "components/yourCosts"
import { useDoctorReferStore } from "./DoctorReferStore"

export default function ReferLanding({...pageProps}) {
  const [ setNotInterested ] = useDoctorReferStore( state => [ state.setNotInterested ] )

  return (
    <div className="flex flex-col">
      <div className="lg:max-w-[1100px] mx-auto">
        <div className="lg:flex lg:gap-10 lg:justify-between lg:items-start">
          <div>
            <PatientInformation invoice={pageProps?.invoice} />
            <PrescriptionDetails invoice={pageProps?.invoice} items={pageProps?.invoice?.details} doctorInfo={pageProps?.productPricingData?.data?.patient} />
          </div>
          <div>
            <YourCosts invoice={pageProps?.invoice} error={pageProps?.error} />
          </div>
        </div>
        <PrescriptionConfirmation
          invoice={pageProps?.invoice} nextPage={pageProps?.nextPage}
          items={pageProps?.invoice?.details}
        />
      </div>
      <Faq />
      <DeferSupplies setNotInterested={setNotInterested} />
    </div>
  )
}