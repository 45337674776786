import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import DoctorReferType from './DoctorReferType'

type DoctorRefer = {
  refer: DoctorReferType,
  setRefer( _newRefer : DoctorReferType ) : void,
  setHasReader( _newHasReader : boolean ) : void,
  setProvider( _newProvider : string ) : void,
  setMemberId( _newMemberId : string ) : void,
  setAddressConfirmed( _newAddressConfirmed : boolean ) : void
  setHasAcceptedTerms( _newHasAcceptedTerms : boolean ) : void
  setOrderConfirmation( _newOrderConfirmation : boolean ) : void
  setOrderComplete( _newOrderComplete : boolean ) : void
  setTotalDueToday( _newTotalDueToday : number ) : void
  setNotInterested( _newNotInterested : boolean ) : void
  setPageLoadCount( _newPageLoadCount : number ) : void
  setOngoingMonthlyCost( _newOngoingMonthlyCost : number ) : void
  setParentPayerPk( _newParentPayerPk : number ) : void
  reset() : void
}

const initialState = {
  refer: {
    hasReader: null,
    provider: ``,
    memberId: ``,
    parentPayerPk: 0,
    addressConfirmed: false,
    hasAcceptedTerms: false,
    orderConfirmation: false,
    orderComplete: false,
    notInterested: false,
    totalDueToday: 0,
    pageLoadCount: 0,
    sensorCost: 0,
    readerCost: 0,
    ongoingMonthlyCost: 0
  }
}

export const useDoctorReferStore = create<DoctorRefer>()(
  persist( ( set ) => {
    return {
      ...initialState,
      setRefer: ( _newRefer : DoctorReferType ) => {
        set({
          refer: _newRefer
        })
      },
      setParentPayerPk: ( _newParentPayerPk : number ) => {
        set( state => ({
          refer: {
            ...state.refer,
            parentPayerPk: _newParentPayerPk
          }
        }) )
      },
      setOngoingMonthlyCost: ( _newOngoingMonthlyCost : number ) => {
        set( state => ({
          refer: {
            ...state.refer,
            ongoingMonthlyCost: _newOngoingMonthlyCost
          }
        }) )
      },
      setReaderCost: ( _newReaderCost : number ) => {
        set( state => ({
          refer: {
            ...state.refer,
            readerCost: _newReaderCost
          }
        }) )
      },
      setSensorCost: ( _newSensorCost : number ) => {
        set( state => ({
          refer: {
            ...state.refer,
            sensorCost: _newSensorCost
          }
        }) )
      },
      setHasReader: ( _newHasReader : boolean ) => {
        set( state => ({
          refer: {
            ...state.refer,
            hasReader: _newHasReader
          }
        }) )
      },
      setProvider: ( _newProvider : string ) => {
        set( state => ({
          refer: {
            ...state.refer,
            provider: _newProvider
          }
        }) )
      },
      setMemberId: ( _newMemberId : string ) => {
        set( state => ({
          refer: {
            ...state.refer,
            memberId: _newMemberId
          }
        }) )
      },
      setAddressConfirmed: ( _newAddressConfirmed : boolean ) => {
        set( state => ({
          refer: {
            ...state.refer,
            addressConfirmed: _newAddressConfirmed
          }
        }) )
      },
      setHasAcceptedTerms: ( _newHasAcceptedTerms : boolean ) => {
        set( state => ({
          refer: {
            ...state.refer,
            hasAcceptedTerms: _newHasAcceptedTerms
          }
        }) )
      },
      setOrderConfirmation: ( _newOrderConfirmation : boolean ) => {
        set( state => ({
          refer: {
            ...state.refer,
            orderConfirmation: _newOrderConfirmation
          }
        }) )
      },
      setOrderComplete: ( _newOrderComplete : boolean ) => {
        set( state => ({
          refer: {
            ...state.refer,
            orderComplete: _newOrderComplete
          }
        }) )
      },
      setNotInterested: ( _newNotInterested : boolean ) => {
        set( state => ({
          refer: {
            ...state.refer,
            notInterested: _newNotInterested
          }
        }) )
      },
      setTotalDueToday: ( _newTotalDueToday : number ) => {
        set( state => ({
          refer: {
            ...state.refer,
            totalDueToday: _newTotalDueToday
          }
        }) )
      },
      setPageLoadCount: ( _newPageLoadCount : number ) => {
        set( state => ({
          refer: {
            ...state.refer,
            pageLoadCount: _newPageLoadCount
          }
        }) )
      },
      reset: () => {
        set( initialState )
      }
    }
  },
  {
    name: `doctor-refer-store`,
    storage: createJSONStorage( () => sessionStorage )
  })
)