import { DueToday } from "components/dueToday"
import { useDoctorReferStore } from "modules/doctorRefer/DoctorReferStore"
import AobInvoice, { AobInvoiceItem } from "types/aobInvoice"
import { formatMoney } from "utils/money"
import PaymentController from "./View/PaymentController"

export default function PaymentDetails({ invoice }: {invoice: AobInvoice}) {
  const [ hasReader, totalDueToday, provider, readerCost ] = useDoctorReferStore( ( state ) => [ state.refer.hasReader, state.refer.totalDueToday, state.refer.provider, state.refer.readerCost ] )

  const items = invoice.details

  const dueToday = hasReader ? totalDueToday - readerCost : totalDueToday


  return (
    <>
      <hr className="border-skyBlue2 mt-7"></hr>
      <div className="my-7">
        <h2 className="text-xl mb-1 md:text-2xl font-sourceserif4">
          { invoice.pay_later_eligible_flag ? `First Bill Expectation` : `Enter Payment Info` }
        </h2>
        {invoice.pay_later_eligible_flag && (
          <p className="text-xs mb-4">{`You will receive your first bill after your insurance processes your claim.`}</p>
        )}
        <table className="table-auto w-full">
          <tbody className="text-xs align-top">
            {
              items?.filter( ( item ) => !hasReader || item.is_sensor )
                ?.map( ( item: AobInvoiceItem ) => (
                  <tr key={item.hcpc_code}>
                    <td className="py-2 capitalize" data-testid={`first-bill-rx-item-label-${item.hcpc_code}`}>{item?.item_description.toLowerCase()}{ item?.is_sensor && invoice.pay_later_eligible_flag ? `- 1 Month Supply` : ( item?.is_sensor ? `- 3 Month Supply` : `` ) }</td>
                    <td className="py-2 pl-2 text-right" data-testid={`first-bill-rx-item-price-${item.hcpc_code}`}>
                      {formatMoney( provider?.length > 0 ? 0 : item?.is_sensor && invoice.pay_later_eligible_flag? ( item?.patient_owes ? item.patient_owes : 0 ) : item?.patient_owes ?? 0 )}
                      {dueToday > 0 ? `` : `/month`}
                    </td>
                  </tr>
                ) )
            }
          </tbody>
        </table>

        <DueToday
          total={hasReader ? totalDueToday - readerCost : totalDueToday}
          labelClassName={`text-xl`}
          contentClassName={`text-xl`}
        />
      </div>
      { ( ( totalDueToday as number ) > 0 ) && <PaymentController />}
    </>
  )
}
