import { InsurancePayer, Payer } from "types/insurancePayer"
import { postInsuranceUpdate } from "./api"
import { reportToSentry } from "utils/reportToSentry"
import React from "react"
import { useDoctorReferStore } from "modules/doctorRefer/DoctorReferStore"

type PostInsuranceUpdateReturn = {
    errors: string[],
    loading: boolean,
    postUpdate: () => Promise<void>
}

export default function usePostInsuranceUpdate( ): PostInsuranceUpdateReturn {

  const [ errors, setErrors ] = React.useState<string[]>( [] )
  const [ loading, setLoading ] = React.useState<boolean>( false )

  const [ parentPayerPk, memberId ] = useDoctorReferStore( state => [ state.refer.parentPayerPk, state.refer.memberId ] )

  const seondaryInsurance: Payer = {
    insurance_type: `SECONDARY`,
    parent_payer_pk: parentPayerPk,
    member_id: memberId
  }

  const insuranceData: InsurancePayer = {
    store_name: `aeroflow_diabetes_english`,
    profit_center_pk: 34,
    payers: [ seondaryInsurance ]
  }

  const postUpdate = async () => {
    try {
      setLoading( true )
      const response = await postInsuranceUpdate( insuranceData )
      // eslint-disable-next-line no-console
      console.log( `response`, response )
      setErrors( response?.errors?.map( ( error: Record<string, unknown> ) => { return error.message }) as string[] ?? [] )
    } catch ( error ) {
      console.error( `Insurance update failed`, error )
      reportToSentry( new Error( `Insurance update failed` ), {
        errorMessage: JSON.stringify( error )
      })
    } finally {
      setLoading( false )
    }
  }

  return {
    errors,
    loading,
    postUpdate
  }
}