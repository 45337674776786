
import { useEffect } from 'react'
import { reportToSentry } from '../utils/reportToSentry'
import useFetchProductPricingData from 'modules/doctorRefer/customHooks/useFetchProductPricingData'
import { ProductPricingData } from 'types/productPricingData'

declare global {
  interface Window {
    NOIBUJS: {
      addCustomAttribute: ( _name: string, _value: string ) => void;
    };
  }
}

type noibuCustomAttrs = Record<string, string | number | boolean>;

type customAttrs = Array<[string, string | number | boolean]>;

/*
 * Noibu JS SDK
 */
function checkSDKExistanceAndAddCustomAttribute( customAttrs: customAttrs ) {
  if ( !window.NOIBUJS ) {
    new Promise( ( resolve ) => {
      window.addEventListener( `noibuSDKReady`, resolve )
    }).then( () => {
      // eslint-disable-next-line no-console
      console.log( `adding custom attributes to noibu` )
      customAttrs.forEach( ( [ key, value ] ) => {
        window.NOIBUJS.addCustomAttribute( key, `${value}` )
      })
    })

    return
  }
  // eslint-disable-next-line no-console
  console.log( `adding custom attributes to noibu` )
  customAttrs.forEach( ( [ key, value ] ) => {
    window.NOIBUJS.addCustomAttribute( key, `${value}` )
  })
}

// Valid noibu custom attributes must have a value
// - otherwise don't send to noibu
const validateNoibuCustomAttrs = ( customAttrs: noibuCustomAttrs ) => {
  const invalidCustomAttrs: customAttrs = []
  if ( !customAttrs ) {
    return {
      validCustomAttrs: [],
      invalidCustomAttrs: []
    }
  }
  const validCustomAttrs = Object?.entries( customAttrs ).filter( ( [ key, value ] ) => {
    if ( typeof value === `boolean` ) {
      return true
    }

    if ( !value ) {
      invalidCustomAttrs.push( [ key, value ] )
    }

    return value
  })

  return {
    validCustomAttrs: [ ...validCustomAttrs ],
    invalidCustomAttrs: [ ...invalidCustomAttrs ]
  }
}

/*
 * component used to access NOIBUJS's addCustomAttribute method to add patient id to user's session in noibu
 */
export default function Noibu() {
  const productPricingData: ProductPricingData = useFetchProductPricingData()

  const patientData = productPricingData.productPricingData?.data?.patient ?? {
    patient_id: ``,
    email_address: ``
  }
  const { patient_id, email_address } = patientData

  const noibuCustomAttrs = {
    patient_id,
    email_address
  }

  useEffect( () => {
    const { validCustomAttrs } = validateNoibuCustomAttrs( noibuCustomAttrs )

    try {
      checkSDKExistanceAndAddCustomAttribute( validCustomAttrs )
    } catch ( error ) {
      console.error( `Error adding custom Noibu attributes` )
      reportToSentry( new Error( `Error adding custom Noibu attributes` ), {
        error: JSON.stringify( error )
      })
    }
  }, [ noibuCustomAttrs ] )

  return null
}